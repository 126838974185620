import React from "react"
import tw from "twin.macro"
import { useForm } from "@formspree/react"

const InputContainer = tw.div`pt-4`
const InputBox = tw.div`border rounded flex items-center text-base font-sans p-1 focus-within:border-green-400 bg-white`
const Input = tw.input`flex-grow focus:outline-none px-2`
const SubscribeButton = tw.button`self-end rounded text-white bg-green-400 hover:bg-green-600 py-1 px-2`
const ThanksMessage = tw.p`font-sans text-sm`


export function NewsletterForm() {
    const [formState, handleFormSubmit] = useForm("xayaqyze")

    if (formState.succeeded) {
        return (
            <ThanksMessage>Thank you for joining our newsletter</ThanksMessage>
        )
    } else {
        return (
            <form onSubmit={handleFormSubmit}>
                <InputContainer>
                    <InputBox>
                        <Input name="email" type="email" id="email" />
                        <SubscribeButton type="submit" disabled={formState.submitting}>Subscribe</SubscribeButton>
                    </InputBox>
                </InputContainer>
            </form>
        )
    }
}
